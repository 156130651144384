import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less';
import App from './App'
import router from './router'
import { VueAxios } from './utils/request'
import { ipc } from '@/utils/ipcRenderer'
import localforage from 'localforage'
import ImSdk from '@/utils/ImConnUtils'
Vue.directive('down', {
  inserted: (el, binding) => {
    el.style.cssText = 'cursor: pointer;color:write;'
    el.addEventListener('click', () => {
      console.log(binding.value)
      let link = document.createElement('a')
      let url = binding.value.messageUrl
      // 这里是将url转成blob地址，
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        link.href = URL.createObjectURL(blob)
        console.log(link.href)
        if(binding.value.messageFileName!=null){
          link.download = binding.value.messageFileName
        }else{
          link.download = binding.value.messageUrl;
        }
        
        document.body.appendChild(link)
        link.click()
      })
    })
  }
})
Vue.use(ElementUI);
// 使用antd
Vue.use(antd)
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)

Vue.use(localforage)
const messageDb = localforage.createInstance({name:'messageDb'})
// 全局注入IPC通信
Vue.prototype.$ipc = ipc
Vue.prototype.$messageDb = messageDb
Vue.prototype.$ImSdk = ImSdk
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  beforeCreate(){
      Vue.prototype.$bus = this;
  }
}).$mount('#app')
