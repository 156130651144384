
import storage from 'store2'
import {requestHttpGet,requestHttpPostByOnline,requestHttpUpload} from '@/api/main'
import apiUrl from '@/utils/config'
let disp = require("@/utils/broadcast");
// const link = "ws://127.0.0.1:48080/ws"
const link = "wss://commonapi.binliyoujia.com/ws"
const heartbeatInterval = 30000;
export default  {
  heartbeatTimeout:{},
  restIm:{},
  im:{},
  //初始化
  initIm:function(vuedom){
    const that = this
    if('WebSocket' in window){
      const im = new WebSocket(link)
      that.im = im
      im.onopen = ()=>{
        console.log("im已连接")
        storage.set('isOnline',true)
        that.loginIm(storage.get('token'))
      }
      im.onclose = (e)=>{
        console.log("im已断开",e)
        storage.set('isOnline',false)
        clearTimeout(that.heartbeatTimeout);
      }
      im.onerror = ()=>{
        console.log("链接失败")
        clearTimeout(that.heartbeatTimeout);
        if(im.readyState != 0){
          setTimeout(function(){
            that.initIm(vuedom)
          },1000)
        }
      }
      im.onmessage= (e)=>{
        console.log("收到消息")
        console.log(e)
        let message = JSON.parse(e.data)
        // 0 - 2 认证消息
        if(message.code == 0 || message.code == 1 || message.code == 2){
          if(message.code == 0){
            storage.set('isOnline',true)
          }
          if(message.code == 2){
            storage.set('isOnline',false)
          }
          vuedom.$bus.$emit("im.auth",message,vuedom);
        }else{
          //发送到消息总线 
          vuedom.$bus.$emit("im.all",message,vuedom);
        }
        that.heartbeat()
      }
    }
  },
  //发送消息
  sendMessage(message) {
    if (this.im && this.im.readyState === WebSocket.OPEN) {
      console.log(message)
      this.im.send(JSON.stringify(message));
      //推送消息
      console.log("准备发送离线",message.type)
      if(message.type == 'CHAT_SEND_TO_ONE_REQUEST' || message.type == 'CHAT_SEND_TO_GROUP_REQUEST'){
        this.sendPushMsg(message.message)
      }
    }

  },
  //登录认证
  loginIm(accessToken){
    let message = {
      type:"AUTH_REQUEST",
      message:{
        accessToken:accessToken
      }
    }
    this.sendMessage(message)
    this.heartbeat()
  },
  //心跳
  sendHearBeat(){
    let message = {
      type:"HEARTBEAT_REQUEST"
    }
    // console.log("发送心跳")
    this.sendMessage(message)
  },
  //心跳机制
  heartbeat() {
    let that = this
    // 清除之前的心跳计时器
    // console.log("清除开启心跳计时")
    clearTimeout(this.heartbeatTimeout);
    // console.log("开启心跳计时",heartbeatInterval)
    // 设置下一个心跳计时器
    this.heartbeatTimeout = setTimeout(function() {
      // console.log("定时发送")
      that.sendHearBeat();
    }, heartbeatInterval);
  },
  //关闭
  closeIm(){
    console.log(this.im)
    if(this.im.readyState == 1){
      this.im.close()
    }
  },


  //业务方法-----------------------------------------------------
  //获取会话列表
  async getSessions(obj,vuedom){
   let res =  await requestHttpGet(apiUrl+"/admin-api/netty/im/sessions/page",obj)
   console.log(res)
   //缓存到本地
   if(obj.type == 'chat'){
    await this.remakeChat(res.data,vuedom)
   }
   if(obj.type == 'group'){
    await this.remakeGroupChat(res.data,vuedom)
   }
  },

  //获取会话信息
  async getSessionsInfo(obj){
    let res =  await requestHttpGet(apiUrl+"/admin-api/netty/im/sessions/get",obj)
    console.log(res)
    return res.data
  },
  //清洗消息体到本地
  async remakeChat(sessions,vuedom){
    let items = []
    for (let index = 0; index < sessions.length; index++) {
      let item = sessions[index];
      items.push(item)
    }
    console.log(vuedom)
    console.log(items)
    await vuedom.$messageDb.setItem("corlist",this.updateTopSort(items))
  },
  //清洗消息体到本地
  async remakeGroupChat(sessions,vuedom){
    let items = []
    for (let index = 0; index < sessions.length; index++) {
      let item = sessions[index];
      items.push(item)
    }
    console.log(vuedom)
    console.log(items)
    await vuedom.$messageDb.setItem("grouplist",this.updateTopSort(items))
  },
  //获取单聊会话
  async getlocalChat(vuedom){
    let res  = await vuedom.$messageDb.getItem("corlist")
    console.log(res)
    return res
  },
  async getlocalGroup(vuedom){
    let res  = await vuedom.$messageDb.getItem("grouplist")
    console.log(res)
    return res
  },
  //更新置顶排序
  updateTopSort(list){
    //获取置顶消息
    let newList = []
    let topList = []
    let dopList = []
    let lowList = []
    let dloList = []
    list.forEach(e=>{
      if(e.isTop){
        if(e.message!=null){
          topList.push(e)
        }else{
          dopList.push(e)
        }
      }else{
        if(e.message!=null){
          lowList.push(e)
        }else{
          dloList.push(e)
        }
      }
    })
    //将置顶消息按照最后消息的时间排序
    console.log(list)
    topList.sort((a, b) => b.message.createTime - a.message.createTime);
    lowList.sort((a, b) => b.message.createTime - a.message.createTime);
    dloList.sort((a, b) => b.createTime - a.createTime);
    dopList.sort((a, b) => b.createTime - a.createTime);
    newList.unshift(...dloList)
    newList.unshift(...lowList)
    newList.unshift(...dopList)
    newList.unshift(...topList)
    return newList
  },
  //获取联系热最近联系人列表
  async getRecentContactsList(){
    let res =  await requestHttpGet(apiUrl+"/admin-api/netty/im/sessions/get-recent-contacts",{})
    return res.data
  },
   //获取店铺内所有设计师
   async getShopDesignerList(){
    let res =  await requestHttpGet(apiUrl+"/admin-api/supplier/user/list-all-simple-designer",{})
    return res.data
  },
  //创建群组
  async createGroup(obj){
    await requestHttpPostByOnline(apiUrl+"/admin-api/netty/im/sessions/create-group",obj)
  },
  //离开或者解散群组
  async leaveGroup(obj){
    await requestHttpGet(apiUrl+"/admin-api/netty/im/sessions/leave-group",obj)
  },
  //删除群组成员
  async addGroupUser(obj){
    await requestHttpPostByOnline(apiUrl+"/admin-api/netty/im/sessions/add-group-user",obj)
  },
  //加入群组成员
  async removeGroupUser(obj){
    await requestHttpPostByOnline(apiUrl+"/admin-api/netty/im/sessions/remove-group-user",obj)
  },
  //获取会话消息列表
  async getMessagePage(obj){
    let res =  await requestHttpGet(apiUrl+"/admin-api/netty/im-message/page",obj)
    res.data.list.forEach(e => {
        e.belong = false
        //判断消息是否是自己发出的
        if(e.messageFrom == storage.get('userId')){
          //是自己
          e.belong = true
        }
        e.members.forEach(m=>{
          if(m.id == storage.get('userId')){
              //自己
              e.selfInfo = m
          }else{
              if(m.id == e.messageFrom){
                e.otherInfo = m
              }
          }
        })
        //格式化自定义消息
        console.log(e.messageCustomExt)
        e.messageCustomExt = JSON.parse(e.messageCustomExt)
        this.getMessageTextAndStatus(e)
    });
    return res.data.list
  },
  //获取消息发送者信息
  getMessageMembersInfo(e){
    let info = {}
    e.members.forEach(m=>{
      if(m.id == e.messageFrom){
        info = m
      }
    })
    return info
  },
  //根据会话设置消息已读
  async setMessageIsReadBySessions(obj){
    // /set-read
    return await requestHttpGet(apiUrl+"/admin-api/netty/im-message/set-read",obj)
  },
  //清晰本地消息
  async getChatMessageBody(e){
    if(e.messageFrom == storage.get('userId')){
      //是自己
      e.belong = true
    }
    e.members.forEach(m=>{
      if(m.id == storage.get('userId')){
          //自己
          e.selfInfo = m
      }else{
          if(m.id == e.messageFrom){
            e.otherInfo = m
            //如果是单聊取会话备注替换用户名称
          }
      }
    })
    if(e.messageCustomExt){
      e.messageCustomExt = JSON.parse(e.messageCustomExt)
    }
  
    this.getMessageTextAndStatus(e)
    return e
  },
  async getGroupMessageBody(e){
    if(e.messageFrom == storage.get('userId')){
      //是自己
      e.belong = true
    }
    e.members.forEach(m=>{
      if(m.id == storage.get('userId')){
          //自己
          e.selfInfo = m
      }else{
          if(m.id == e.messageFrom){
            e.otherInfo = m
            //如果是单聊取会话备注替换用户名称
          }
      }
    })
    if(e.messageCustomExt){
      e.messageCustomExt = JSON.parse(e.messageCustomExt)
    }
    this.getMessageTextAndStatus(e)
    return e
  },
  //发送消息已读回执
  sendReadMessage(msgId){
    const msg = {
      type: "CHAT_SEND_TO_READ_BACK_REQUEST",
      message: {
          id:msgId,
          accessToken:storage.get('token')
      }
    }
    this.sendMessage(msg)
  },
  async sendForwardImagesMessage(userid, chatType,res,ext,extType) {
		const msg = {
			type: chatType == 'chat' ? "CHAT_SEND_TO_ONE_REQUEST" : "CHAT_SEND_TO_GROUP_REQUEST",
			message: {
				msgId: new Date().getTime(),
				toUser: userid,
				fromUser: storage.get('userId'),
				accessToken: storage.get('token'),
				content: {
					type: chatType,
					attr: "img",
					msg: "图片消息",
					fileUrl: res.messageUrl,
					ext:ext,
					extType:extType,
					customExt: {},
					fileLength: res.messageFileLength,
					fileName: res.messageFileName,
					audioLength: 0,
					imageWidth: 0,
					imageHeight: 0
				}
			}
		}
		console.log(msg)
		this.sendMessage(msg)
	},
	async sendForwardFileMessage(userid, chatType, res,ext,extType) {
		//上传文件到服务器
		const msg = {
			type: chatType == 'chat' ? "CHAT_SEND_TO_ONE_REQUEST" : "CHAT_SEND_TO_GROUP_REQUEST",
			message: {
				msgId: new Date().getTime(),
				toUser: userid,
				fromUser: storage.get('userId'),
				accessToken: storage.get('token'),
				content: {
					type: chatType,
					attr: "file",
					msg: "文件消息",
					fileUrl: res.messageUrl,
					ext: ext,
					extType:extType,
					customExt: {},
					fileLength: res.messageFileLength,
					fileName: res.messageFileName,
					audioLength: 0,
					imageWidth: 0,
					imageHeight: 0
				}
			}
		}
		console.log(msg)
		this.sendMessage(msg)
	},
	async sendForwardVideoMessage(userid, chatType,res,ext,extType) {
		//上传文件到服务器
		const msg = {
			type: chatType == 'chat' ? "CHAT_SEND_TO_ONE_REQUEST" : "CHAT_SEND_TO_GROUP_REQUEST",
			message: {
				msgId: new Date().getTime(),
				toUser: userid,
				fromUser: storage.get('userId'),
				accessToken: storage.get('token'),
				content: {
					type: chatType,
					attr: "video",
					msg: "视频消息",
					fileUrl: res.messageUrl,
					ext: ext,
					extType:extType,
					customExt: {},
					fileLength: res.messageFileLength,
					fileName: res.messageFileName,
					audioLength: 0,
					imageWidth: 0,
					imageHeight: 0
				}
			}
		}
		console.log(msg)
		this.sendMessage(msg)
	},
	async sendForwardAudioMessage(userid, chatType, res,ext,extType) {
		//上传音频到服务器
		const msg = {
			type: chatType == 'chat' ? "CHAT_SEND_TO_ONE_REQUEST" : "CHAT_SEND_TO_GROUP_REQUEST",
			message: {
				msgId: new Date().getTime(),
				toUser: userid,
				fromUser: storage.get('userId'),
				accessToken: storage.get('token'),
				content: {
					type: chatType,
					attr: "audio",
					msg: "语音消息",
					fileUrl: res.messageUrl,
					ext: ext,
					extType:extType,
					customExt: {},
					fileLength: 0,
					fileName: res.messageFileName,
					audioLength: res.messageAudioLength,
					imageWidth: 0,
					imageHeight: 0
				}
			}
		}
		console.log(msg)
		this.sendMessage(msg)
	},
  //发送转发消息方法
	async sendForwardMessage(userid, chatType, textContent, vuedom,ext,extType) {
		if (textContent.match(/^\s*$/)) return
		const msg = {
			type: chatType == 'chat' ? "CHAT_SEND_TO_ONE_REQUEST" : "CHAT_SEND_TO_GROUP_REQUEST",
			message: {
				msgId: new Date().getTime(),
				toUser: userid,
				fromUser: storage.get('userId'),
				accessToken: storage.get('token'),
				content: {
					type: chatType,
					attr: "txt",
					msg: textContent,
					fileUrl: "",
					ext: ext,
					extType:extType,
					customExt: {},
					fileLength: 0,
					fileName: "",
					audioLength: 0,
					imageWidth: 0,
					imageHeight: 0
				}
			}
		}
		console.log(msg)
		this.sendMessage(msg)
	},
  //发送文本消息方法
  async sendTextMessage(userid,chatType,textContent,vuedom,ext,extType){
    if (textContent.match(/^\s*$/)) return
      //获取当前用户出图时间
      let designer = await requestHttpGet(apiUrl+'/admin-api/supplier/user/get-designer-pc-im')
      if(designer.data != null){
        if(textContent.includes("{outpic}")){
          console.log(designer.data.designer.acceptanceDate.join("-"))
          const regex = new RegExp("{outpic}", 'g');
          textContent = textContent.replace(regex, designer.data.designer.acceptanceDate.join("-"));
        }
      }
      const msg = {
        type: chatType =='chat'?"CHAT_SEND_TO_ONE_REQUEST":"CHAT_SEND_TO_GROUP_REQUEST",
        message: {
            msgId:new Date().getTime(),
            toUser:userid,
            fromUser:storage.get('userId'),
            accessToken:storage.get('token'),
            content: {
                type: chatType,
                attr: "txt",
                msg: textContent,
                fileUrl: "",
                ext: ext,
                extType:extType,
                customExt:{},
                fileLength:0,
                fileName:"",
                audioLength:0,
                imageWidth:0,
                imageHeight:0
            }
        }
      }
      this.sendMessage(msg)
  },
  //发送自定义消息
  async sendCustomMessages(userid,chatType,objCustom,vuedom,ext,extType){
    const msg = {
      type: chatType =='chat'?"CHAT_SEND_TO_ONE_REQUEST":"CHAT_SEND_TO_GROUP_REQUEST",
      message: {
          msgId:new Date().getTime(),
          toUser:userid,
          fromUser:storage.get('userId'),
          accessToken:storage.get('token'),
          content: {
              type: chatType,
              attr: "custom",
              msg: "订单消息",
              fileUrl: "",
              ext: ext,
              extType:extType,
              customExt:objCustom,
              fileLength:0,
              fileName:"",
              audioLength:0,
              imageWidth:0,
              imageHeight:0
          }
      }
    }
    this.sendMessage(msg)
  },
  async sendImagesMessage(userid,chatType,fileInfo,ext,extType){
    //上传图片到服务器
    console.log(fileInfo)
    let obj = {
      file:fileInfo,
      path:new Date().getTime()
    }
    let res = await requestHttpUpload(apiUrl+"/admin-api/infra/file/upload",obj)
    const msg = {
      type: chatType =='chat'?"CHAT_SEND_TO_ONE_REQUEST":"CHAT_SEND_TO_GROUP_REQUEST",
      message: {
          msgId:new Date().getTime(),
          toUser:userid,
          fromUser:storage.get('userId'),
          accessToken:storage.get('token'),
          content: {
              type: chatType,
              attr: "img",
              msg: "图片消息",
              fileUrl: res.data,
              ext: ext,
              customExt:{},
              extType:extType,
              fileLength:fileInfo.size,
              fileName:fileInfo.name,
              audioLength:0,
              imageWidth:0,
              imageHeight:0
          }
      }
    }
    console.log(msg)
    this.sendMessage(msg)
  },
  async sendFileMessage(userid,chatType,fileInfo,ext,extType){
    //上传文件到服务器
    console.log(fileInfo)
    let obj = {
      file:fileInfo,
      path:new Date().getTime()
    }
    let res = await requestHttpUpload(apiUrl+"/admin-api/infra/file/upload",obj)
    const msg = {
      type: chatType =='chat'?"CHAT_SEND_TO_ONE_REQUEST":"CHAT_SEND_TO_GROUP_REQUEST",
      message: {
          msgId:new Date().getTime(),
          toUser:userid,
          fromUser:storage.get('userId'),
          accessToken:storage.get('token'),
          content: {
              type: chatType,
              attr: "file",
              msg: "文件消息",
              fileUrl: res.data,
              ext: ext,
              extType:extType,
              customExt:{},
              fileLength:fileInfo.size,
              fileName:fileInfo.name,
              audioLength:0,
              imageWidth:0,
              imageHeight:0
          }
      }
    }
    console.log(msg)
    this.sendMessage(msg)
  },
  async sendVideoMessage(userid,chatType,fileInfo,ext,extType){
    //上传文件到服务器
    console.log(fileInfo)
    let obj = {
      file:fileInfo,
      path:new Date().getTime()
    }
    let res = await requestHttpUpload(apiUrl+"/admin-api/infra/file/upload",obj)
    const msg = {
      type: chatType =='chat'?"CHAT_SEND_TO_ONE_REQUEST":"CHAT_SEND_TO_GROUP_REQUEST",
      message: {
          msgId:new Date().getTime(),
          toUser:userid,
          fromUser:storage.get('userId'),
          accessToken:storage.get('token'),
          content: {
              type: chatType,
              attr: "video",
              msg: "视频消息",
              fileUrl: res.data,
              ext: ext,
              extType:extType,
              customExt:{},
              fileLength:fileInfo.size,
              fileName:fileInfo.name,
              audioLength:0,
              imageWidth:0,
              imageHeight:0
          }
      }
    }
    console.log(msg)
    this.sendMessage(msg)
  },
  async sendAudioMessage(userid,chatType,fileInfo,ext,extType){
    //上传音频到服务器
    console.log(fileInfo)
    let fileOfBlob = new File([fileInfo.src], new Date().getTime() + '.mp3')
    console.log(fileOfBlob)
    let obj = {
      file:fileOfBlob,
      path:new Date().getTime()
    }
    let res = await requestHttpUpload(apiUrl+"/admin-api/infra/file/upload",obj)
    const msg = {
      type: chatType =='chat'?"CHAT_SEND_TO_ONE_REQUEST":"CHAT_SEND_TO_GROUP_REQUEST",
      message: {
          msgId:new Date().getTime(),
          toUser:userid,
          fromUser:storage.get('userId'),
          accessToken:storage.get('token'),
          content: {
              type: chatType,
              attr: "audio",
              msg: "语音消息",
              fileUrl: res.data,
              ext: ext,
              extType:extType,
              customExt:{},
              fileLength:0,
              fileName:fileOfBlob.name,
              audioLength:fileInfo.length,
              imageWidth:0,
              imageHeight:0
          }
      }
    }
    console.log(msg)
    this.sendMessage(msg)
  },
  




  //获取提示文字及状态
  getMessageTextAndStatus(e){
    if(e.messageType != 'custom'){
      return 
    }
    if(e.messageCustomExt.type == "selectOrder"){
      //结算单消息
      e.messageCustomExt.actionType = "亲，已帮您选择好商品规格，喜欢的话可点击“立即接单”完成下单"
      e.messageCustomExt.type = "selectOrder"
      return 
    }
    if(e.messageCustomExt.type == 'designerProduct'){
      e.messageCustomExt.actionType = ""
      e.messageCustomExt.actionNo = ""
      e.messageCustomExt.actionName = "查看详情"
      return 
    }
    if(e.messageCustomExt.type == 'renderProduct'){
      e.messageCustomExt.actionType = ""
      e.messageCustomExt.actionNo = ""
      e.messageCustomExt.actionName = "查看详情"
      return 
    }
    e.messageCustomExt.actionNo = e.messageCustomExt.no
    e.messageCustomExt.actionName = this.getOrderButtonName(e.messageCustomExt)
    e.messageCustomExt.actionStatus = true
  },
  getOrderButtonName(e){
    let res = ""
    if(e.refundStatus!=0){
      switch (e.refundStatus) {
        case 10:
          res = "部分退款完成"
          break;
        case 20:
          res = "全部退款完成"
          break;
        default:
          res = "退款中"
      }
      return res
    }
    switch (e.status) {
      case 0:
        res = "待支付"
        break;
      case 10:
        res = "对接中"
        break;
      case 20:
        res = "待确认需求"
        break;
      case 30:
        res = "制图中"
        break;
      case 40:
        res = "待确认效果图"
        break;
      case 41:
        res = "待审核效果图"
        break;
      case 50:
        res = "已完成"
        break;
      case 60:
        res = "待确认改图需求"
        break;
      case 70:
        res = "改图制图中"
        break;
      case 100:
        res = "已关闭"
        break;
      default:
        res = "申请退款"
    }
    return res
  },
  //离线消息
  async sendPushMsg(message){
    console.log("发送离线")
    console.log(message)
    if(message.content.type == "group"){
      let nickname = ""
      let avatar = ""
      let pushArr = []
      let content = message.content.msg
      let res = await requestHttpGet(apiUrl+"/admin-api/netty/im/sessions/get-group-member-info",{id:message.toUser})
      if(res.data!=null){
        res.data.forEach(e=>{
          if(message.fromUser == e.id){
            nickname = e.nickname
            avatar = e.avatar
          }else{
            pushArr.push(e.id)
          }
        })
      }
      if(pushArr.length>0){
        let obja = {
          getui_alias: pushArr, //接收人
          title:nickname+"在群组向您发送了一条新消息。",
          content:content,
          payload:{"type":4,"orderId":message.toUser,chatType:'group',nickname:nickname,avatarurl:avatar},
          request_id:new Date().getTime()+"s"
        }
        console.log(obja)
        let ras = await requestHttpPostByOnline('https://fc-mp-d9355a6b-424f-47cc-b10b-4865ae8b23a6.next.bspapp.com/sysPushForUserId', obja)
        console.log(ras)
      }
    }else{
      let nickname = ""
      let avatar = ""
      let pushArr = []
      let content = message.content.msg
      let res = await requestHttpGet(apiUrl+"/admin-api/netty/im/sessions/get-member-info",{ids:message.toUser+","+message.fromUser})
      if(res.data!=null){
        res.data.forEach(e=>{
          if(message.fromUser == e.id){
            nickname = e.nickname
            avatar = e.avatar
          }else{
            pushArr.push(e.id)
          }
        })
      }
      if(pushArr.length>0){
        let obja = {
          getui_alias: pushArr, //发送人
          title:nickname+"向您发送了一条新消息。",
          content:content,
          payload:{"type":1,"orderId":message.toUser,chat:'chat',nickname:nickname,avatarurl:avatar},
          request_id:new Date().getTime()+"s"
        }
        console.log(obja)
        let ras = await requestHttpPostByOnline('https://fc-mp-d9355a6b-424f-47cc-b10b-4865ae8b23a6.next.bspapp.com/sysPushForUserId', obja)
        console.log(ras)
      }
       
      //获取用户是否在线
        // console.log(uid,"调用离线通知~") 
        // console.log(uid,message)
        // //发送人头像
        // //发送人user_name
        // let nickname = storage.get('UserName')
        // let avatarurl = storage.get('UserHeader')
        // let contentz = "您有新的聊天信息啦,请注意查收"
        // if (message.type == "txt") {
        //   if(message.msg.length > 40){
        //     contentz = message.msg.substr(0,35)+"..."
        //   }else{
        //     contentz = message.msg
        //   }
        // }
        // if (message.type == "txt") {
        //   if(message.msg.indexOf('[') >= 0){
        //     contentz = "[表情]"
        //   }
        // }
        // if (message.type == "img") {
        //   contentz = "[图片]"
        // }
        // if (message.type == "file") {
        //   contentz = "[文件]"
        // }
        // if (message.type == "audio") {
        //   contentz = "[语音]"
        // }
        // if (message.type == "video") {
        //   contentz = "[视频]"
        // }
        // if (message.type == "custom") {
        //   contentz = "[链接]"
        // }
        // if(uid){
        //   let muid = ""
        //   if(uid.indexOf("im")>=0){
        //     muid = uid.split("im")[1]
        //   }else{
        //     muid = uid.split("bk")[1]
        //   }
        //   let obja = {
        //     getui_alias: muid, //发送人
        //     title:nickname+"向您发送了一条新消息。",
        //     content:contentz,
        //     payload:{"type":1,"orderId":muid,chatType:message.chatType,nickname:nickname,avatarurl:avatarurl},
        //     request_id:new Date().getTime()+"s"
        //   }
        //   console.log(obja)
        //   if(muid!=""){
        //     let ras = await requestHttpPostByOnline('https://fc-mp-d9355a6b-424f-47cc-b10b-4865ae8b23a6.next.bspapp.com/sysPushForUserId', obja)
        //     console.log(ras)
        //   }
        // }
    }
    
  },
}